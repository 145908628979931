.pedido{
	display: flex;
	background: #eeeeee;
	font-size: 12px;
	margin-bottom: 30px;

	.btn-medium{
		min-width:inherit;
	}

	&.pedido-realizado{

		.pedido-header{
			background-color: #333333;
		}

	}

	&.pedido-aprovado{

		.pedido-header{
			background-color: #333333;
		}

	}

	&.pedido-transporte{

		.pedido-header{
			background-color: $theme;
		}

	}

	&.pedido-entregue{

		.pedido-header{
			background-color: $theme;
		}

	}

	.pedido-header{
		background-color:darken(#eee, 20%);
		color: #FFF;
		padding: 10px;
		background-color:#000000;
		font-size:12px;

		.num{
			margin-bottom: 10px;

			.maior{
				font-size:15px;
			}

		}

		@include media-breakpoint-up(xl){
			width: 25%;
		}
	}

	.title{
		color:#263351;
	}


	.pedido-body{
		display:flex;
		font-weight:bold;
		flex-grow:1;
		align-items: center;
		font-size:14px;
		background-color:#f4f5fb;

		@include media-breakpoint-down(sm){
			flex-wrap: wrap;

			.pedido-col{
				width: 100%;
			}
		}
	}

	.pedido-col{
		padding: 5px 15px 5px 35px;
		min-height:50px;
		color:#444;

		.btn{
			padding:11px 12px;
		}

		.title{
			color:#263351;
			margin-bottom: 5px;
		}

		.title,
		.content{
			line-height:1;
		}

		@include media-breakpoint-up(md){
			width: 33.333%;

			& + .pedido-col{
				border-left:1px solid #AAAAAA;
			}

			&:last-child{
				text-align:right;

			}

		}
	}
	
	@include media-breakpoint-down(lg){
		flex-direction:column;

		.pedido-body{
			padding: 15px;
		}
	}
}