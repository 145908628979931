.form-meus-dados{
	max-width: 100%;
	width: 600px;
	margin-left: auto;
	margin-right: auto;

	form{

		label,
		.text-gray{
			margin-bottom:0;
			font-size:14px;
			color:#656565;
		}

		.form-group{
			margin-bottom:24px;

			& > .col-md-3,
			& > .col-md-4{
				align-self:center;
				text-align:right;
			}

			.text-gray{
				font-size:14px;
			}

			.form-control{
				background-color:#EEEEEE;
				@include sombra(#EEEEEE);
				border-radius:0;
				color:#333333;
				border:none;
				font-size:13px;
				min-height:42px;
				padding-left:20px;

				@include placeholdIt{
					color:#333333;
				}

			}

			.btn{
				
			}

		}

		.radio-custom{

			label{
				font-size:13px;
				color:#878787;
			}

		}

		@include media-breakpoint-up(md){

			label{
				margin-bottom:0;
			}

		}

	}

}