.resumo-pedido{

	@include media-breakpoint-up(md){

		.btn-gigantesco{
			min-width:313px;
		}

	}

	.header{
		display: flex;
		flex-wrap: wrap;
		color: #FFF;
		font-size: 14px;

		& > *{
			width: 50%;
			padding: 15px;

			@include media-breakpoint-down(xs){
				width: 100%;
			}
		}

		.title,
		.obs{
			font-size:12px;
		}

		.status-area{
			background: #282828;
		}

		.status{
			font-size: 18px;
		}

		.date-area{
			background: #3A3A3A;
		}

		.data{
			font-size: 17px;
		}

		.dia{
			font-size: 40px;
		}

		.mes-ano{
		}

	}

	.body{
		padding: 25px 32px 15px 32px;
		border-top:none;
		box-shadow:0 0 6px 3px #EEEEEE;
		min-height:345px;
	}

	.passos-pedido{
		list-style: none;
		padding-left: 0;
		margin-bottom: 40px;
		font-size:22px;
		padding-top:18px;
		border-top:1px solid #CFCFCF;

		li {
			line-height:1;
		}

		.texto{
			font-size:12px;
			line-height:1;
			display:inline-block;
			color:#878787;
		}

		li:before{
			@extend .fas;
			color:#FFF;
		}

		@include media-breakpoint-down(xs){
			li{
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 3em;
				position: relative;

				&:before{
					content:'';
					width:1em;
					height:1em;
					position:absolute;
					background: #282828;
					border-radius:100%;
					left:1em;
					top:calc(50% - 0.5em);
					z-index: 2;
				}

				& + li:after{
					content:'';
					height:100%;
					border-left:3px solid #282828;
					position:absolute;
					left: calc(1.5em - 2px);
					top:-50%;
				}

				&.active:before{
					content:"\f0d1";
					width:2em;
					height:2em;
					top:calc(50% - 1em);
					left:0.5em;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		@include media-breakpoint-up(sm){
			display: flex;

			li{
				width: 25%;
				text-align: center;
				position: relative;
				padding-top:3em;

				&:before{
					content:'';
					width:1em;
					height:1em;
					border-radius: 100%;
					background: #282828;
					position:absolute;
					top:1em;
					left:calc(50% - 0.5em);
					color: #FFF;
					z-index:2;
					display: flex;
					justify-content:center;
					align-items:center;
				}

				& + li:after{
					content:'';
					width: 100%;
					border-top: 3px solid #282828;
					position: absolute;
					left:-50%;
					top:calc(1.5em - 2px);
				}

				&.active{
					&:before{
						content:"\f0d1";
						width:2em;
						height:2em;
						top:0.5em;
						left:calc(50% - 1em);
						
					}
				}
			}
		}
	}

	@include media-breakpoint-down(md){

		.header{

			.dia{
				font-size:30px;
			}

		}

	}

	@include media-breakpoint-up(xl){

		.body{

			.btn{
				min-width:313px;
			}

		}

	}

}