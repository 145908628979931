@import "_fontfaces";

// FONTS USADAS
.mnt-blk{
	font-family: 'montserratblack';
}

.mnt-bld{
	font-family: 'montserratbold';
}

.mnt-ex-bld{
	font-family: 'montserratextrabold';
}

.mnt-reg{
	font-family: 'montserratregular';
}

.mnt-semi-bld{
	font-family: 'montserratsemibold';
}

.pops-reg{
	font-family: 'poppinsregular';
}

body{
	color:#333333;
	font-family: 'poppinsregular';
	font-size:15px;
}

.internas img{
	@extend .img-fluid;
}

.owl-carousel .owl-item img{
	display:inline-block;
	width:auto;
}

img{
	display:inline-block;
}

#app{
	min-height:100vh;
	overflow:hidden;
	display:flex;
	flex-direction:column;
}

a,
.btn{
	transition:all 0.6s linear;
}

.title-radios{
	padding:10px 0;
	font-size:27px;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		text-decoration:none;
		color:inherit;
	}

}

@include text-emphasis-variant('.text-theme',$theme);
@include text-emphasis-variant('.text-black',#000);
@include text-emphasis-variant('.text-green',#2CB200);

.bg-gray{
	background-color:#F7F7F7;
}

.border-radius-100{
	border-radius:100%;
}

.fnt-17{
	font-size:17px;
}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

.box-bolinhas{
	display:none;
	width:0;
	height:0;
	transition:width 0.6s linear;

	.bolinha{
		display:inline-block;
		width:(1000 / 46) * 1%;
		height:0;
		@include margin(0,3);
		background-color:#FFF;
		border-radius:100%;
	}

	&.animado{
		width:46px;
		display:inline-flex;
		margin-left:3px;

		.bolinha-up{
			animation:bolaUp 1.4s infinite alternate;
		}

		.bolinha-down{
			animation:bolaDown 1.4s infinite alternate;
		}

		.bolinha{
			height:10px;
		}

	}

}

.owl-carousel.borda-primeiro-item .owl-item,
.owl-carousel.borda-primeiro-item .owl-stage,
.internas-categorias .col-xl-4.md-4{
	display:flex;
	align-items:flex-end;
}

.alert{
    border-radius:0;
    @extend .animated;
    @extend .fadeIn;

    &.alert-success,
    &.alerta-success{
        background: map-get($cores,'green-darken-2');
        color:#FFF;
    }

    &.alert-danger,
    &.alerta-danger{
        background: map-get($cores,'red-darken-2');
        color:#FFF;
    }

    &.alert-info,
    &.alerta-info{
        background: map-get($cores,'blue-darken-2');
        color:#FFF;
    }

    &.alert-warning,
    &.alerta-warning{
        background: map-get($cores,'yellow-darken-2');
        color:#FFF;
    }
}

.alertas{
	position: fixed;
    z-index:900;
	bottom:0;
	right:0;
	padding:15px;
	max-width:100%;
	min-width:250px;

	.alert{
		box-shadow:0 0 8px rgba(#000,0.8);
	}
}


body:not(.webp) {
	// #historia{
	// 	background-image: url(../images/bg-time-line.webp);
	// }

	// .frase-efeito{
	// 	background: url(../images/marca-dagua-263x269.png) center center no-repeat;
	// }

	// #personagens{
	// 	&:before{
	// 		background: url(../images/onda-personagens.png) center top repeat-x;
	// 	}
	// }
}

body.webp {
	// #historia{
	// 	background-image: url(../images/bg-time-line.webp);
	// }

	// .frase-efeito{
	// 	background-image: url(../images/marca-dagua-263x269.webp);
	// 	background-position: center center;
	// 	background-repeat: no-repeat;
	// }

	// #personagens{
	// 	&:before{
	// 		background-image: url(../images/onda-personagens.webp);
	// 	}
	// }
}

.stars-ratting{
	display: inline-block;
	width: 178px;
	height: 40px;
	position: relative;
	background: #CBCBCB;

	&:before,
	&:after{
		content: '';
		display: block;
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
	}

	&:before{
		width: 100%;
		background: url(../images/pattern-stars.png) center center;
		z-index: 2;
	}

	&:after{
		background-color: #F1C930;
		z-index: 1;
		transition: width 0.3s linear;
	}

	$n:0;

	@for $i from 0 through 10{
		$n: $n + 0.5;

		$w: $n * 20%;

		&[data-nota="#{$n}"]:after{
			width: $w;
		}
	}
}