.form-contato{
	margin-bottom:20px;

	form{

		label{
			color:#656565;
			font-size:14px;
		}

		.form-control{
			color:#333333;
			font-size:13px;
			background-color:#EEEEEE;
			@include sombra(#EEEEEE);
			border-radius:0;
			min-height:42px;
			padding-left:22px;
			border-color:#EBEBEB;
			resize:none;

			@include placeholdIt{
				color:#333333;
			}

		}

		.form-group-textarea{
			margin-bottom:36px;
		}

	}

	@include media-breakpoint-up(lg){

		max-width:1125px;
		margin-right:auto;
		margin-left:auto;

	}

}