.btn{

	&.min-width-172{
		min-width:172px;
	}

	&.fnt-15{
		font-size:45px;
	}

	&.btn-pill{
		border-radius: 2rem;
	}

	&.border-60{
		border-radius:60px;
	}

	&.btn-pdg-9-16{
		padding:9px 16px;
	}


	&.btn-padrao{
		@extend .btn-pdg-9-16;
		font-size:14px;
		@extend .min-width-172;

		&.btn-padrao-lg{

			@include media-breakpoint-up(md){
				min-width:280px;
			}

			padding:26px 40px;

		}

	}

	&.btn-black{
		@include button-variant(#000,#000);
		color:#FFF;
	}

	&.btn-gray{
		@include button-variant(#DDDDDD,#DDDDDD);
		color:#FFF;
	}

	&.btn-theme{
		@include button-variant($theme,$theme);
		color:#FFF;
	}

	&.btn-menos-gray{
		@include button-variant(lighten(#E3E3E3,8%),lighten(#E3E3E3,8%));
	}

	&.btn-gray-to-theme{
		@include button-variant(darken(#DDDDDD,20%),darken(#DDDDDD,20%));
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten($theme,10%),lighten($theme,10%));
			color:#FFF;
		}

	}

	&.border-bottom-6-black{
		border-bottom-width:6px;
		border-style:solid;
		border-bottom-color:#000;
	}

	&.btn-finaliza-compra{
		font-weight:bold;
		box-shadow:0 2px #aeb3aa;
		@include button-variant(#6AC017, #6AC017);
		line-height:1;
		padding-left: 50px;
		min-width: 200px;
		text-align: left;
		position: relative;
		color:#FFF;
		font-size:13px;

		&:before,
		&:after{
			content:'';
			display: block;
			position: absolute;
		}

		&:before{
			width:20px;
			height: 10px;
			background: currentcolor;
			left:0.75rem;
			top:calc(50% - 5px);
		}

		&:after{
			border:10px solid transparent;
			border-left-color:#FFF;
			left: calc(0.75rem + 20px);
			top: calc(50% - 10px);
		}

		span{
			display: block;
			padding-left: 0.75rem;
			padding-bottom:0.35rem;
			padding-top:0.35rem;
			white-space:normal;

			&:before{
				content:'';
				height:100%;
				border-left: 1px solid darken(#6AC017,1%);
				position: absolute;
				top:0;
				left: 50px;
			}
		}
	}

}