.media-incrivel{
	text-align:center;
	
	@include media-breakpoint-up(lg){
		text-align:left;
		display:flex;
		align-items:flex-start;
	}


	.box-img,
	.box-icon{
		margin-bottom:5px;
		margin-left:auto;
		margin-right:auto;
		
		@include media-breakpoint-up(lg){
			margin-right:10px;
			margin-left:unset;
			margin-bottom:0;
		}

	}

	&.media-incrivel-servicos{
		margin-bottom:55px;

		.box-icon{
			font-size:29px;
			width:(73 / 29) * 1em;
			height:(73 / 29) * 1em;
			line-height:(73 / 29) * 1em;
			background-color:$theme;
			color:#FFF;
			border-radius:100%;
			display:flex;
			align-items:center;
			justify-content:center;
		}

		.media-text{
			
			@include media-breakpoint-up(lg){
				max-width:260px;
			}

			.title{
				font-size:15px;
				font-family: 'montserratextrabold';
				color:#333;
				margin-bottom:3px;
				transition:color 0.6s linear;
			}

		}

		&:hover{

			.media-text{

				.title{
					color:$theme;
				}

			}

		}


	}

	&.media-incrivel-montar{
		margin-bottom:75px;

		.media-text{

			.title{
				color:$theme;
			}

			.title,
			.subtitle{
				font-size:17px;
				font-family: 'montserratextrabold';
			}

		}

	}

}