.card-produto{
	width:100%;
	max-width:288px;
	margin-bottom:22px;
	padding-left:24px;
	padding-right:20px;
	border-left:1px solid #DDDDDD;
	text-align:center;
	margin-left:auto;
	margin-right:auto;
	display:flex;
	flex-direction:column;

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.header-text{
		margin-bottom:10px;
		padding-right:12px;
		padding-left:2px;
		display:flex;
		flex-direction:column;

		.categoria{
			font-size:12px;
			color:$theme;
			font-family: 'montserratsemibold';
			margin-bottom:2px;
		}

		.desc{
			font-size:14px;
			color:#333333;
			font-family: 'montserratextrabold';
		}

	}

	.header-figure{
		border:1px solid #DDD;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
	}

	.rodape-text{
		padding-left:2px;
		padding-right:3px;
		color:#333;

		@include media-breakpoint-up(lg){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			align-items:center;
		}

		.parte-preco{

			@include media-breakpoint-up(lg){
				flex-grow:1;
				max-width:160px;
			}

			.preco-total{
				font-size:20px;
				font-family: 'montserratblack';
			}

			.parcelamento{
				font-size:12px;
			}

		}

		.parte-compra{
			padding-top:20px;

			@include media-breakpoint-up(lg){
				padding-top:0;
				display:flex;
				justify-content:flex-end;
			}

			.box-circle{
				font-size:22px;
				width:(40 / 22) * 1em;
				height:(40 / 22) * 1em;
				line-height:(40 / 22) * 1em;
				display:flex;
				justify-content:center;
				background-color:$theme;
				color:#FFF;
				border-radius:100%;
				margin-left:auto;
				margin-right:auto;
				align-items:center;
			}

		}

	}

}