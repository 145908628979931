.carrinho-computador{
	font-family: 'montserratblack';
	margin-bottom:40px;

	.carrinho-header{
		font-size:14px;

		.produto-area{
			flex-grow:0 !important;
		}

	}

	.carrinho-item{

		&,
		.qtde,
		.vlr-unit,
		.vlr-ttl{
			border-color:#EEE;
			border-width:4px;
		}

		.produto-area{
			align-items:center;
		}

		.qtde{
			display:flex;
			align-items:center;
			justify-content:center;
		}

		.vlr-ttl,
		.vlr-unit{
			flex-grow:2;
			text-align:center;
		}

		.form-control{
			font-size:23px;
			@include place(#282828);
		}

		.excluir-item{
			background-color:#000;
		}

		.descricao{
			color:#000000;
			font-size:12px;
			font-family: 'montserratextrabold';
		}

		.vlr-unit{
			
			.de{
				font-size:17px;
			}

			.special{
				font-size:25px;
			}

		}

	}

	.carrinho-footer-computador{
		background-color:$theme;
		color:#FFF;
		display:flex;
		flex-wrap:wrap;
		justify-content:flex-end;
		text-align:center;

		.btn{
			color:inherit;
			border:none;
			box-shadow:none;
		}

		& > *{
			width:100%;
		}

		.parte-total{
			background-color:$theme;
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
			justify-content:flex-end;
			flex-direction:column;
			padding:22px 30px;
			font-family: 'montserratblack';

			.total-mesmo{
				font-size:25px;
			}

		}

		.parte-adicionar{
			background-color:#000;
			
			.btn{
				padding:26px 50px;
			}

		}

	}

}