.form-escolha-pc{

	.selecione-text{
		font-size:17px;
		font-family: 'montserratextrabold';

		.fas{
			color:$theme;

			@include media-breakpoint-only(xs){

				&{
					transform:rotate(90deg);
				}
				
			}

		}

	}

	.form-group-radio{

		&.box-shadow-radio{

			label{
				box-shadow:0 0 0 $theme;
				transition:box-shadow 0.6s linear;
			}

			input:checked ~ label{
				box-shadow:0 0 8px $theme;
			}
			
		}

		input{
			position:fixed;
			left:-200vw;
			opacity:0;
		}

	}

}