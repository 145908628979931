.select-peca{
	margin-bottom:20px;

	.btn-select{
		box-shadow:none;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		width:100%;
		text-align:left;
		background-color:#282828;
		color:#FFF;
		font-size:13px;
		font-family: 'montserratextrabold';
		border:none;
		padding:13px 15px;
		border-radius:6px 6px 0 0;

		.icone{
			transition:all 0.45s linear;
			width:20%;
			text-align:right;
		}

		&[aria-expanded="true"]{

			.icon{
				transform:rotate(180deg);
			}

		}

		&:hover,
		&:focus,
		&[aria-expanded="true"]{
			background-color:$theme;
		}

		.text{
			flex-grow:1;
			max-width:490px;
			white-space: nowrap;
			width:80%;
    		overflow: hidden;
    		text-overflow: ellipsis;
		}

	}

	.select-content::-webkit-scrollbar{
		width:6px;
		background-color:#EEE;
		max-height:44px;
	}

	.select-content{
		list-style-type:none;
		margin-bottom:0;
		padding-left:0;

		&::-webkit-scrollbar-thumb{
		  background: #282828;
		}
		
		&.show{
			overflow:auto;

			@include media-breakpoint-up(lg){
				max-height:340px;
			}

		}

		.form-group{
			margin-bottom:0;

			input:checked{

				& ~ label{
					border-right-color:#2CB200;
					border-left-color:#2CB200;
				}

			}

			.label-flex{
				padding-top:5px;
				border-left:2px solid #EEEEEE;
				padding-bottom:5px;
				margin-bottom:0;
				border-radius:0;
				transition:all 0.6s linear;

				@include media-breakpoint-down(md){
					padding:15px;
				}

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					justify-content:space-between;
				}

				.figure-box{

					@include media-breakpoint-up(lg){
						width:15%;
						margin-bottom:0;
					}

				}

				.content{
					font-size:12px;
					font-family: 'montserratextrabold';
					 text-align:center;

		    		@include media-breakpoint-up(lg){
		    			text-align:left;
		    		}

					span{
						@extend .text-theme;
					}

					@include media-breakpoint-up(lg){
						width:85%;
					}

				}

			}

		}

		li{

			&:last-child{
				border-bottom:2px solid #EEEEEE;
			}


			& + li{
				border-top:2px solid #EEEEEE;
			}

		}

	}

}