.form-newsletter{
	position:relative;


	.form-group{

		& > .form-control{

			@include media-breakpoint-up(lg){
				padding-left:100px;
			}

		}

	}

	form{
		position:relative;
		
		@include media-breakpoint-down(lg){
			width:100%;
			text-align:center;
		}
		
		@include media-breakpoint-up(xl){
			max-width:541px;
			margin-left:auto;
		}

		.imagem-circle{

			@include media-breakpoint-down(lg){
				margin-bottom:15px;
			}

			@include media-breakpoint-up(xl){
				position:absolute;
				right:calc(100% - 86px);
				top:-70px;
				z-index:67;
			}

		}

		.input-group,
		.form-group,
		.form-control,
		.input-group-prepend .btn{
			border-radius:8px;
			overflow:hidden;
			
			@include media-breakpoint-down(sm){
				border-radius:8px !important;
				overflow:hidden !important;
			}

		}

		.form-control{
			min-height:51px;
			background-color:#EEEEEE;
			font-size:14px;
			border:none;
			padding-left:40px;
		}

		.input-group-prepend{

			@include media-breakpoint-down(sm){
				padding-top:15px;
				width:100%;

				.btn{
					width:100%;
					padding:10px 20px;
				}

			}

		}

		.btn{
			font-size:15px;
		}

	}

}