.form-custom{
	padding-top:20px;
	margin-bottom:20px;

	.custom-control-label{

		&:before,
		&:after{
			top:calc(50% - 0.6em);
		}

	}

	label,
	.text-gray,
	.custom-control-label,
	select,
	.form-control{
		margin-bottom:0;
		font-size:14px;
		color:#656565;
	}

	.form-control,
	.custom-select{
		background-color:transparent;
		border-radius:0;
		box-shadow:2px 2px 4px #818181;
		color:#333333;
		border:none;
		font-size:13px;
		min-height:42px;
		padding-left:20px;

		@include placeholdIt{
			color:#333333;
		}

	}

}