.form-newsletter-rodape{
	margin-bottom:35px;

	form{

		.input-group{
			font-family: 'montserratbold';

			.form-control{
				background-color:#DDDDDD;
				@include sombra(#DDDDDD);
				@include place(#FFF);
				font-size:13px;
				height:auto;
				border:none;
				min-height:48px;
				padding-left:20px;

				@include media-breakpoint-up(lg){
					border-radius:30px 0 0 30px;
				}

			}

			.input-group-prepend{

				& > .btn{
					font-size:12px;

					@include media-breakpoint-up(lg){
						border-radius:0 30px 30px 0;
					}

				}

			}

		}

	}

}