.form-alterar-endereco{

	form{

		label{
			color: #656565;
			font-size:14px;
		}

		.form-control{
			border:none;
			min-height:42px;
			font-size:13px;
			background-color:#EEEEEE;
			@include sombra(#EEEEEE);
			@include place(#333);
			border-radius:0;
		}

	}

}