.banner{
	text-align:center;
	max-width:1920px;
	margin-left:auto;
	margin-right:auto;

	.carousel-item{
		position:relative;
		z-index:50;
		margin-bottom:0;
	}

}

.bg-gray.pt-comum{
	padding-top:94px;
}

.borda-primeiro-item{

	&:before{
		content:'';
		display:block;
		position:absolute;
		top:0;
		left:0;
		height:100%;
		width:1px;
		background-color:#FFF;
		z-index:66;
	}

}

.chegaram{
	padding-bottom:30px;
}

.imagem-full{
	max-width:1920px;
	margin-left:auto;
	margin-right:auto;

	figure{
		margin-bottom:0;
	}

}

.mais-vendidos{
	padding-top:50px;
	padding-bottom:45px;
}

.solucoes{
	padding-top:25px;
	text-align:center;

	.img-destaque{
		position:relative;

		.btn{
			margin-top:-43px;
			z-index:800;
			position:relative;
		}

		.controls-figure{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			display:flex;
			flex-wrap:wrap;
			justify-content:center;

			&:before{
				content:'';
				display:block;
				position:absolute;
				top:20px;
				opacity:0;
				transition:opacity 0.6s linear;
				right:14px;
				width:61px;
				height:159px;
				background:url('../images/bg-decoracoes-solucoes-img-destaque.png');
			}

			&.active{

				&:before{
					opacity:1;
				}

			}

			.graphics{
				position:absolute;
				top:0;
				padding-left:20px;
				padding-right:14px;
				left:0;
				width:100%;
				height:100%;
				display:flex;

				.column-big{
					height:(172 * 100%) / 380;
				}

				.column-small{
					height:(72 * 100%) / 380;
				}

				& > *{
					background-color:$theme;
					width:4px;
					height:0;
					transition:height 0.6s linear;
					@include margin(0,6);
				}

			}

		}

	}

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.img-destaque{
		margin-bottom:0;
	}

}

.destaques{
	padding-top:25px;
	padding-bottom:20px;
}

.clientes{
	padding-top:20px;
}

.segura-carousel{
	position:relative;

	.segura-controls{
		max-width:200px;
		margin:0 auto;
		
		@include media-breakpoint-up(lg){
			max-width:100%;
			position:absolute;
			top:0;
			left:0;
			display:flex;
			width:100%;
			height:100%;
			align-items:center;
			justify-content:center;
		}

		.clearfix{
			width:100%;
			font-size:22px;

			& > *{
				position:relative;
				top:-11px;
			}

		}

	}

	@include media-breakpoint-up(lg){
		padding-left:25px;
		padding-right:25px;
	}

}

.fa-long-arrow-alt-left,
.fa-long-arrow-alt-right{
	@include margin(0,4);
	font-size:22px;

	@include hover-focus{
		color:$theme;
	}

}

.lazyimage{
	margin-right:auto;
	margin-left:auto;
}

.owl-carousel{

	img{
		width:auto;
		display:inline-block;
	}

}