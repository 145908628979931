.form-buscas-topo{

	@include media-breakpoint-up(lg){
		flex-grow:1;
		max-width:618px;
		margin-right:20px;
	}

	@include media-breakpoint-down(md){
		padding-left:15px;
		padding-right:15px;
		padding-top:20px;
	}

	form{

		label{
			width:100%;
			margin-bottom:2px;

			@include media-breakpoint-up(lg){
				@include sr-only;
			}

		}

		.input-group{

			.form-control{
				@include place(#878787);
				font-size:12px;
				min-height:41px;
				border:none;
				height:auto;
				border-radius:30px 0 0 30px;

				@include media-breakpoint-down(md){
					color:#000;

					@include placeholdIt(){
						color:transparent;
					}
					
				}

				&,
				& ~ .input-group-prepend .btn{
					box-shadow:none;

					.icon{
						vertical-align:text-bottom;
					}
					
					@include media-breakpoint-up(lg){
						background-color:#FFFFFF;
					}

					@include media-breakpoint-down(md){
						background-color:darken(#FFF,15%);
						padding:0 20px;
						@include sombra(darken(#FFF,15%));
					}

				}

			}

			.input-group-prepend{

				.btn{
					background-color:#FFF;
					padding:9px 18px;
					border:none;
					border-radius:0 30px 30px 0;
				}

			}

		}

	}

}