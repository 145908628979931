.media-incrivel-custom{

	& > *{
		margin-bottom:35px !important;
	}

	.box-icon{
		font-size:28px;
		font-family: 'montserratextrabold';
		width:(73 / 28) * 1em;
		height:(73 / 28) * 1em;
		line-height:(73 / 28) * 1em;
		background-color:$theme;
		color:#FFF;
		text-align:center;
		border-radius:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		box-shadow:0 0 0 $theme;
		transition:box-shadow 0.6s linear;
	}

	.media-text{
		
		@include media-breakpoint-up(lg){
			max-width:260px;
		}

		.title{
			font-family: 'montserratextrabold';
			margin-bottom:2px;
			transition:color 0.6s linear;
			
			.bar{

				position:relative;

				&:before{
					content:'';
					display:block;
					width:0;
					height:1px;
					background-color:$theme;
					position:absolute;
					top:100%;
					left:0;
					transition:width 0.6s linear;
				}

			}

		}

		.desc{
			font-size:13px;
		}

	}

	&:hover{

		.box-icon{
			box-shadow:0 0 0 6px rgba($theme,0.4);
		}

		.media-text{

			.title{
				color:$theme;

				.bar{

					&:before{
						width:100%;
					}

				}

			}

		}

	}

}