.paginacao-velha{
	margin-bottom:0;
	font-size:14px;

	.page-item{

		& > a{
			border-color:#DDDDDD;
			color:#666;
		}

		&.active,
		&:hover{

			& > a,
			& > .page-link{
				@include button-variant($theme,#DDD);
				color:#FFF;
			}

		}

	}

}