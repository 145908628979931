.redes-sociais{

	& > *{
		@include margin(0,15);
	}

	&.redes-sociais-topo{
		color:#999999;
	}

	&.redes-sociais-rodape{
		color:#7D7D7D;
		font-size:22px;
		margin-bottom:30px;
	}

	&.redes-sociais-rodape{

		& > *{

			&:hover{
				color:$theme;
			}

		}

	}

}