.form-cadastro{

	form{

		select{
			@extend .form-select-custom;
		}

		.label-fake,
		legend{
			font-family: 'montserratbold';
		}

		label{
			font-size:14px;
		}

		label,
		.label-fake{
			color:#000;
		}

		.label-fake{
			font-size:14px;
		}

		.form-group{
			margin-bottom:20px;
		}

		.texto-diferente{
			font-size:14px;
			color:#333333;
		}

		legend{
			font-size:17px;
			color:#333333;
			font-weight:bold;
		}

		.form-control{
			@include place(#333);
			font-size:13px;
			border-radius:0;
			min-height:42px;
			padding-left:22px;
			background-color:#EEEEEE;
			border-color:#EBEBEB;
			@include sombra(#EBEBEB);
		}

	}

	@include media-breakpoint-up(lg){

		.limitador{
			max-width:895px;
			margin-right:0;
			margin-left:auto;
		}

	}

}