.header-internas{
	text-align:center;
	padding-top:52px;
	padding-bottom:20px;
	margin-bottom:40px;
	border-bottom:1px solid #DDD;

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.title{
		font-size:28px;
		font-family: 'montserratblack';
		color:$theme;
		margin-bottom:0;
	}

	@include media-breakpoint-up(lg){

	}

}