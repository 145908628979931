.mbl-controls{
	display:flex;
	flex-wrap:wrap;
	justify-content:space-between;
	align-items:center;
	background-color:#FFF;
	z-index:900;
	border-bottom:1px solid #ccc;
	color:$theme;
	padding:0 12px 0 0;

	.logo-mobile{
		max-width:180px;
		padding:10px;
		margin-bottom:0;
	}

	.btn-responsivo{
		border:none;
		box-shadow:none;

		.bar{
			display:block;
			width:28px;
			height:4px;
			background-color:$theme;
			position:relative;

			@include beforeAfter{
				content:'';
				display:block;
				position:absolute;
				left:0;
				width:100%;
				height:100%;
				background-color:inherit;
			}

			&:before{
				top:8px;
			}

			&:after{
				bottom:8px;
			}

			&,
			&:before,
			&:after{
				transition:all 0.6s linear;
			}

		}

	}

	.box-user{
		font-size:22px;

		& > *{
			@include margin(0,7);

			@include hover-focus{
				color:darken($theme,10%);
			}

		}

	}

	.parte-login{


		& > *{
			@include margin(0,5);
		}

	}

}

body.menu-active{


	.btn-responsivo{
		transform:rotate(45deg);

		.bar{

			&:before{
				top:0;
			}

			&:after{
				bottom:0;
			}

			@include beforeAfter{
				transform:rotate(90deg);
			}

		}

	}

}