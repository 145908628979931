.forma-pagamento-pedido{
	border:1px solid #000;

	.title-header{
		font-size:15px;
	}

	.bold{

		&.bold-total{
			color:#171717;
		}

	}

	.forma{
		font-size:13px;
		color:#656565;
	}

	th{
		font-weight:normal;
	}

	.header{
		padding: 15px;

		h2{
			font-size: 15px;
			margin-bottom:10px;
		}
		
	}

	.table{
		color:#484848;
		font-size:13px;

		th,
		td{
			vertical-align:baseline;
			border:none;
		}

		.total-mesmo{
			font-size:20px;
			font-family: 'montserratbold';
			color:$theme;
		}

		thead{
			color:#333333;
			font-size:15px;
		}

		tbody {
			tr:nth-child(odd) {
				td, th{
					background: #EDEBEB;

				}
			}
		}

		tfoot{
			th, td{
				background: #EDEBEB;
			}
		}
	}

	.media{
		padding: 10px 15px;
		color:#656565;
		font-size:13px;

		&.media-endereco{
			color:#333333;
			font-size:15px;
		}

		&.media-localizacao{
			padding-top:0;
		}

		&.media-usuario{
			padding-bottom:0;
		}

	}

	.icon-area{
		width: 30px;
		font-size:20px;
		color:#333;
		margin-right:3px;
	}

	hr{
		max-width:286px;
		margin-top:6px;
		margin-bottom:10px;
		margin-left:auto;
		margin-right:auto;
	}

	@include media-breakpoint-up(lg){

		.forma{

			img{
				margin-right:12px;
			}

		}

	}

}