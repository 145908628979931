.menu-principal{
	flex-grow:1;
	margin-bottom:0;
	padding-left:0;
	list-style-type:none;

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		max-width:730px;
		margin-left:auto;
	}

	& > li{

		&.active > a,
		&:hover  > a{
			
			@include media-breakpoint-down(md){
				background-color:$theme;
				color:#FFF;
				border-color:darken($theme,10%);
			}

		}

		&.active > a,
		&:hover  > a{

			@include media-breakpoint-up(lg){
				color:$theme;
			}

		}

		& + li{
			position:relative;

			&:before{
				content:'';
				display:block;
				position:absolute;
				right:calc(100% + 25px);
				top:50%;
				transform:translateY(-50%);
				width:1px;
				height:12px;
				background-color:#CCCCCC;
			}

		}

		& > a{

			@include media-breakpoint-down(md){
				padding:10px 15px;
				display:block;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

		}

	}

}