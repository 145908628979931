.sidebar-montar-pc{

	.menu-montar{
		padding-left:0;
		list-style-type:none;
		margin-bottom:20px;
		font-size:14px;
		font-family: 'montserratbold';

		li{
			display:flex;
			min-height:48px;
			flex-wrap:wrap;
			background-color:#EEEEEE;
			flex-direction:column;
			transition:all 0.6s linear;
			justify-content:center;

			@include hover-focus{
				background-color:darken(#EEE,5%);
			}

			@include media-breakpoint-up(sm){
				min-width:274px;
			}

			& + li{
				border-top:1px solid #DDDDDD;
			}

			.icon{
				vertical-align:bottom;
				margin-right:10px;

			}

			& > a{
				display:block;
				word-break:break-all;
				padding:11px 25px 11px 5px;
			}

			&.active,
			&.nao-active{
				border-color:#FFF;

				& > a{
					color:#FFF;
				}

				.icon-processador-black{
					@extend .icon-processador;
				}

				.icon-memoria-black{
					@extend .icon-memoria;
				}

				.icon-placa-video-black{
					@extend .icon-placa-video;
				}

				.icon-hd-black{
					@extend .icon-hd;
				}

				.icon-placa-mae-black{
					@extend .icon-placa-mae;
				}

				.icon-optico-black{
					@extend .icon-optico;
				}

				.icon-gabinet-black{
					@extend .icon-gabinet;
				}

				.icon-fonte-black{
					@extend .icon-fonte;
				}

				.icon-cabo-black{
					@extend .icon-cabo;
				}

				.icon-windows-black{
					@extend .icon-windows;
				}


			}

			&.active{

				& > a{
					background-color:#2DB200;
					position:relative;

					&:after{
						content:'';
						display:inline-block;
						position:absolute;
						top:50%;
						right:0;
						transform:translateY(-50%);
						@extend .icon;
						@extend .icon-checked;
					}

				}

				@include hover-focus{
					
					& > a{
						background-color:darken(#2DB200,5%);
					}

				}

			}

			&.nao-active{

				& > a{
					background-color:#E51825;
					position:relative;

					&:before{
						content:'';
						border:10px solid transparent;
						border-left-color:#E51825;
						display:block;
						position:absolute;
						left:100%;
						top:50%;
						transform:translateY(-50%);
					}

				}

				@include hover-focus{
					
					& > a{
						background-color:darken(#E51825,5%);

						&:before{
							border-left-color:darken(#E51825,5%);
							transition:border-left-color 0.6s linear;
						}

					}

				}

			}

			&.pagina-atual{

				.icon{

				}

			}

		}

	}

}