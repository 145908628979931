.carousel-indicators-custom{
	margin:0;
	z-index:auto;
	position:absolute;
	bottom:24px;
	left:15px;
	@include make-container();
	@include make-container-max-widths();
	justify-content:flex-start;

	li{
		z-index:100;
		position:relative;
		width:58px;
		height:7px;
		@include margin(0,6);
	}

}