.forms-login{
	font-size:13px;

	button{
		padding:9px 10px;
	}

	& > *{
		padding-top:5px;
	}

	.title-form{
		color: #000;
		font-size: 15px;
		margin-bottom: 10px;
	}

	ul{
		padding-left:15px;
		margin-bottom:10px;
	}

	form{
		width: 300px;
		max-width: 100%;
		margin-left:auto;
		margin-right:auto;

		label{
			font-size:13px;
			color:#000;
			margin-bottom:5px;
		}

		.form-control{
			min-height:40px;
			border-color:#cacaca;
			@include sombra(#cacaca);
		}

		.esqueceu-senha{
			font-size:11px;
			color:#767676;

			&:hover{
				color:#263351;
			}

		}

	}

	@include media-breakpoint-down(sm){
		.btn{
			width: 100%;
		}
	}

	@include media-breakpoint-up(md){
		.col-lg-6{
			display: flex;

			form{
				display: flex;
				flex-direction: column;
			}

			.btn-area{
				margin-top: auto;
			}

			// &:first-child{
			// 	border-right:1px solid #cacaca;
			// }

			&:last-child{
				border-left:1px solid #cacaca;
			}
		}
	}
}