.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 310px 306px;

	&.facebook{
		width: 34px;
		height: 32px;
		background-position: -54px -155px;
	}

	&.google-plus{
		width: 34px;
		height: 32px;
		background-position: -98px -155px;
	}

	&.icon-aviso-fonte{
		width: 52px;
		height: 45px;
		background-position: -66px -90px;
	}

	&.icon-buscas{
		width: 18px;
		height: 17px;
		background-position: -132px -241px;
	}

	&.icon-cabo-black{
		width: 20px;
		height: 27px;
		background-position: -288px -76px;
	}

	&.icon-cabo{
		width: 20px;
		height: 27px;
		background-position: -288px -113px;
	}

	&.icon-cartao{
		width: 21px;
		height: 14px;
		background-position: -160px -241px;
	}

	&.icon-cesta-peq{
		width: 25px;
		height: 22px;
		background-position: -92px -208px;
	}

	&.icon-cesta{
		width: 47px;
		height: 41px;
		background-position: -128px -90px;
	}

	&.icon-checked{
		width: 20px;
		height: 20px;
		background-position: -219px -208px;
	}

	&.icon-chevron-down-custom{
		width: 15px;
		height: 12px;
		background-position: -191px -241px;
	}

	&.icon-circle-user{
		width: 44px;
		height: 43px;
		background-position: 0px -155px;
	}

	&.icon-correct{
		width: 20px;
		height: 20px;
		background-position: -189px -208px;
	}

	&.icon-fonte-black{
		width: 21px;
		height: 20px;
		background-position: -158px -208px;
	}

	&.icon-fonte{
		width: 21px;
		height: 20px;
		background-position: -127px -208px;
	}

	&.icon-gabinet-black{
		width: 16px;
		height: 28px;
		background-position: 0px -278px;
	}

	&.icon-gabinet{
		width: 16px;
		height: 28px;
		background-position: -288px -224px;
	}

	&.icon-hd-black{
		width: 22px;
		height: 28px;
		background-position: -288px 0px;
	}

	&.icon-hd{
		width: 22px;
		height: 28px;
		background-position: -288px -38px;
	}

	&.icon-lego{
		width: 41px;
		height: 27px;
		background-position: -185px -90px;
	}

	&.icon-memoria-black{
		width: 36px;
		height: 23px;
		background-position: 0px -208px;
	}

	&.icon-memoria{
		width: 36px;
		height: 23px;
		background-position: -46px -208px;
	}

	&.icon-monitor-computador-black{
		width: 35px;
		height: 29px;
		background-position: -243px -78px;
	}

	&.icon-monitor-computador{
		width: 35px;
		height: 29px;
		background-position: -186px -155px;
	}

	&.icon-optico-black{
		width: 20px;
		height: 27px;
		background-position: -288px -150px;
	}

	&.icon-optico{
		width: 20px;
		height: 27px;
		background-position: -288px -187px;
	}

	&.icon-phone-maior{
		width: 56px;
		height: 55px;
		background-position: 0px -90px;
	}

	&.icon-phone{
		width: 17px;
		height: 17px;
		background-position: -26px -278px;
	}

	&.icon-placa-mae-black{
		width: 34px;
		height: 28px;
		background-position: -243px -155px;
	}

	&.icon-placa-mae{
		width: 34px;
		height: 28px;
		background-position: -243px -117px;
	}

	&.icon-placa-video-black{
		width: 35px;
		height: 29px;
		background-position: -243px -39px;
	}

	&.icon-placa-video{
		width: 35px;
		height: 29px;
		background-position: -243px 0px;
	}

	&.icon-processador-black{
		width: 27px;
		height: 27px;
		background-position: -37px -241px;
	}

	&.icon-processador{
		width: 27px;
		height: 27px;
		background-position: 0px -241px;
	}

	&.icon-usuario{
		width: 18px;
		height: 19px;
		background-position: -249px -208px;
	}

	&.icon-windows-black{
		width: 19px;
		height: 18px;
		background-position: -74px -241px;
	}

	&.icon-windows{
		width: 19px;
		height: 18px;
		background-position: -103px -241px;
	}

	&.logo{
		width: 233px;
		height: 80px;
		background-position: 0px 0px;
	}

	&.twitter{
		width: 34px;
		height: 32px;
		background-position: -142px -155px;
	}

}
