#loading-page {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  z-index:56500;
  background-color:rgba(#000,0.5);
}

#loading-page .content{
  width:112px;
  height:112px;
}

#loading-page .box1,
#loading-page .box2,
#loading-page .box3 {
  border: 16px solid #FFF;
  box-sizing: border-box;
  position: absolute;
  display: block;
}
#loading-page .box1 {
  width: 112px;
  height: 48px;
  margin-top: 64px;
  margin-left: 0px;
  animation: anime1 4s 0s forwards ease-in-out infinite;
}
#loading-page .box2 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 0px;
  animation: anime2 4s 0s forwards ease-in-out infinite;
}
#loading-page .box3 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 64px;
  animation: anime3 4s 0s forwards ease-in-out infinite;
}

