.btn-irtopo{
	display:none;
	position:fixed;
	bottom:20px;
	right:-50%;
	width:2.5em;
	z-index:995;
	height:2.5em;
	line-height:2.5em;
	text-align:center;
	font-size:20px;
	padding:0;
	transition:all 0.6s linear;
	box-shadow:0 0 8px rgba(#000,0.5);

	&:hover{
		transform:scale(1.1);
	}

	&.fixo{
		right:20px;
		animation:fadeInRight 0.6s linear;
	}

	@include media-breakpoint-up(lg){
		display:block;
	}

}