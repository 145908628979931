.tabs-produto{
	margin-bottom:30px;
	font-size:14px;
	color:#040303;

	.tab-title{
		font-size:20px;
		margin-bottom:15px;
	}

	.nav-tab-prod{
		margin-bottom:0;
		display: flex;
		flex-wrap:wrap;
		list-style:none;
		padding-left:0;

		a{
			display: block;
			padding:5px 20px;
			background: #CCCCCC;
			color:#000;
			font-size:13px;
			min-width:116px;

			&:hover{
				background: darken(#CCCCCC, 10%);
				text-decoration: none;
			}
		}

		.active a,
		.active a:hover{
			background: #ECECEC;
			color:#000;
			cursor: default;
		}
	}


	.tab-produto{
		padding: 15px;
		display: none;

		&.active {
			display: block;
			animation: fadeIn 0.3s linear;
		}
	}
}