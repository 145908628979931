.topo{

	.bg-menu{
		position:fixed;
		top:0;
		left:0;
		background-color:rgba(#000,0.5);
		width:100%;
		opacity:0;
		transition:opacity 0.6s linear;
	}

	.logado{
		display:none !important;
	}

	&.topo-logado{

		.logado{
			display:flex !important;
		}

		.nao-logado{
			display:none !important;
		}

	}

	.bar-topo{
		background-color:#EEEEEE;
		font-size:12px;
		font-family: 'montserratregular';
		padding-top:6px;
		padding-bottom:4px;

		.container{

			& > *{
				margin-bottom:6px;
			}

		}

	}

	.nav-content{
		
		@include media-breakpoint-up(lg){
			@include make-container();
			@include make-container-max-widths();
		}

		.logo-desktop{

			@include media-breakpoint-down(md){
				padding:15px;
			}

		}

		@include media-breakpoint-down(md){
			position:fixed;
			width:230px;
			height:100%;
			overflow:auto;
			border-left:1px solid $theme;
			background-color:#FFF;
			z-index:1000;
			top:0;
			right:-230px;
			transition:right 0.6s linear;
		}

		& > * {

			@include media-breakpoint-up(lg){
				display:flex;
				width:100%;
				flex-wrap:wrap;
				align-items:center;
			}

		}

		.parte-menu{

			@include media-breakpoint-up(lg){
				padding-top:20px;
				padding-bottom:16px;
			}

		}

		.parte-user{

			@include media-breakpoint-up(lg){
				position:relative;
				padding-top:24px;
				padding-bottom:22px;
				background-color:#333333;
				color:#FFF;

				@include beforeAfter{
					content:'';
					display:block;
					width:100vw;
					height:100%;
					background-color:inherit;
					position:absolute;
					top:0;
				}

				&:before{
					left:100%;
				}

				&:after{
					right:100%;
				}

			}

			.medias-topo{
				flex-grow:1;
				font-family: 'montserratextrabold';

				@include media-breakpoint-down(md){
					display:none;
				}

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					max-width:416px;
					margin-left:auto;
				}

				.media-incrivel{
					
					.media-icon{
						position:relative;

						.circle{
							font-size:14px;
							width:(30 / 14) * 1em;
							height:(30 / 14) * 1em;
							line-height:(30 / 14) * 1em;
							background-color:$theme;
							color:#FFF;
							text-align:center;
							border-radius:100%;

							@include media-breakpoint-up(lg){
								position:absolute;
								top:0;
								right:-18px;
							}

						}

					}

					.media-icon{

						@include media-breakpoint-up(lg){
							margin-right:8px;
						}

					}

					&.media-incrivel-carrinho{

						.media-icon{

							@include media-breakpoint-up(lg){
								margin-right:26px;
							}

						}

					}

				}

			}

		}

	}

	.bar-dropdown-full{
		background-color:#282828;
		color:#FFF;
		position:relative;

		@include media-breakpoint-down(md){
			display:none !important;
		}

		.botao-categorias{
			width:100%;
			max-width:186px;
			display:block;

			.btn{
				padding:0;
				box-shadow:none;
				border:none;
				width:100%;
				color:inherit;
				height:100%;
			}

		}

		.area-dropdown{
			box-shadow:0 0 8px #000;

			@include media-breakpoint-up(lg){
				position:absolute;
				top:100%;
				left:0;
				width:100%;
				background-color:#FFF;
				z-index:900;
			}

			.content{

				@include media-breakpoint-up(lg){
					@include make-container();
					padding-top:35px;
					padding-bottom:5px;
					@include make-container-max-widths();
					padding-right:0;
					padding-left:0;
				}

			}

		}

	}

	&.fx{

		.mbl-controls{
			
			@include media-breakpoint-down(md){
				position:fixed;
				top:0;
				left:0;
				width:100%;
				box-shadow:0 0 8px rgba(#000,0.5);
				background-color:#FFF;
				border-color:transparent;
				animation:fadeInDown 0.6s linear;
			}

		}

	}

}

body{

	&.sombra{

		.bg-menu{
			z-index:970;
			opacity:1;
			height:100%;
		}

	}

	&.menu-active{

		.topo{

			.nav-content{
				right:0;
			}

		}

	}

}