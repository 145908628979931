.card-dropdown-full{
	margin-left:auto;
	margin-right:auto;
	text-align:center;
	padding-top:27px;
	padding-bottom:27px;
	transition:background-color 0.6s linear;
	color:#FFF;
	word-break:break-all;
	height:100%;

	.content-card{
		padding-left:10px;
		padding-right:10px;

		.title{
			font-size:14px;
			font-family: 'montserratbold';
		}

	}

	&:hover{
		background-color:$theme;
	}

}

.botao-categorias{

	& > .btn{
		position:relative;

		&:before{
			content:'';
			display:block;
			transform:translateX(-50%);
			border:12px solid transparent;
			position:absolute;
			left:50%;
			bottom:0;
			z-index:929;
			transition:border-bottom-color 0.6s linear;
		}

		&:focus .card-dropdown-full{
			background-color:$theme;
		}

	}

	&:hover{

		& > .btn{
			background-color:$theme;

			&:before{
				border-bottom-color:#FFF;
			}

		}

		@include media-breakpoint-up(lg){

			.area-dropdown{
				display:block !important;
			}

		}

	}

}