@import "_home.scss";

.internas{
	padding-bottom:45px;

	form{

		.form-group,
		.input-group{

			.form-control.form-select-custom,
			.form-control.custom-select,
			select.form-control{
				background-image:url('../images/chevron-select.png');
				-webkit-appearance:none;
				background-repeat:no-repeat;
				background-position:calc(100% - 12px) center;
			}

		}

	}

	.campo-obrigatorio{
		font-size:13px;
		color:$theme;
	}

	.hr-divider{
		border-bottom:8px solid #DDDDDD;
	}

	&.internas-finalizar-compra{

		.subtitle{
			font-size:27px;
			color:#333333;
		}

		.title-small,
		.subtitle{
			font-family: 'montserratbold';
		}

	}

	&.internas-meus-servicos{

		.row{

			.col-lg-4{

				@include media-breakpoint-up(lg){

					&,
					& > a{
						display:flex;
						flex-wrap:wrap;
						align-items:flex-start;
					}

				}

			}

		}

	}

	&.internas-meus-enderecos,
	&.internas-meus-enderecos-detalhes,
	&.internas-meus-pedidos,
	&.internas-meus-pedidos-detalhes,
	&.internas-meus-servicos{

		.subtitle{
			color:#333;
			font-size:27px;
			font-family: 'montserratbold';
			margin-bottom:30px;
		}

	}

	&.internas-produtos-detalhes{

		lazyimage{
			display:inline-block;
		}

		.internas-content{
			padding-top:35px;
		}

		.fotos-detail-prod{

			.h5{
				color:#000000;
				font-size:12px;
				margin-bottom:22px;
			}

		}

		.title-valor{
			font-size:35px;
			line-height:1;
			margin-bottom:0;

			.text-small{
				font-size:15px;
			}

		}

		.rating{

			.fa{
				margin:0 2px;

				&:first-child{
					margin-left:0;
				}

				&:last-child{
					margin-right:0;
				}

			}

		}

		.divider-hr{
			border-top:5px solid #EBEBEB;
		}

		.row-imagens{

			& > *{

				@include margin(0,15);

				a{
					position:relative;
					display:block;
					box-shadow:-1px 3px 8px #878787;
					transition:all 0.6s linear;
					position:relative;

					&:before{
						content:'';
						display:block;
						width:100%;
						height:100%;
						position:absolute;
						top:0;
						left:0;
						background-color:transparent;
						transition:all 0.6s linear;
					}

				}

				.zoomGalleryActive{
					box-shadow:none;
				}

			}

		}

		.pdf-custom{
			text-align:center;
			padding-top:10px;
			margin-bottom:20px;
			word-break:break-all;

			.figure-box{
				font-size:80px;
				line-height:1;
				margin-bottom:5px;
			}

			.title-pdf{
				font-size:26px;
			}

			&:hover{
				color:$theme2;
			}

		}

		.media-legal{
			font-size: 40px;
			margin-bottom:35px;
			text-align:center;
			align-items:center;

			.nota{
				color: #656565;
			}
		}

		.details-prod-text{
			font-size: 16px;
			color: #383838;

			.cod{
				font-weight:bold;
			}

			.card-body-cep{
				color:#333333;
				font-size:12px;

				.fa-truck{
					margin-right:5px;
					font-size:15px;
				}

				.cep-text{
					font-size:10px;
					color:#333333;
				}

			}

			.avaliacoes{
				font-size:10px;
				align-self:center;
				color:#263351;
				font-weight:bold;
			}

			.card{

				& + .card{
					position:relative;

					&:before{
						content:'';
						display:block;
						position:absolute;
						right:0;
						bottom:100%;
						width:15px;
						height:15px;
						background-color:#ececec;
					}

				}

				.btn-theme.btn-expanded{
					font-size:18px;

					.fa{
						font-size:25px;
						margin-right:3px;
					}

				}

			}

			.nome,
			.cod{
				color: #000;
			}

			.nome{
				font-size: 18px;
				color:#000;
				margin-bottom: 15px;
			}

			.cod{
				margin-bottom: 20px;
				font-size:12px;
				color:#000;
			}

			.rating{
				color:#e6e6e6;
				font-size:13px;

				.full{
					color: #EAA931;
				}

				.half{
					position: relative;

					&:after{
						content: "\f089";
						color: #EAA931;
						position:absolute;
						left:0;
					}
				}
			}

			.bg-grey{
				background: #ececec;
			}

			.card{
				border-radius:0;
				border:none;

				.btn-redondo{

					.fa{
						margin-right:10px;
						font-size:26px;
					}

				}

				.card-body{

					& > .row{
						align-items:center;
						margin-bottom:10px;
					}

				}

				.form-control{
					border-color:#cecece;
					min-height:44px;
					border-right:none;
					font-size:12px;
					color:#8E8E89;
					border-radius:0;

					@include placeholdIt{
						color:#8E8E89;
					}

				}

				.input-group-addon{

					.btn{
						border-color:#cecece !important;
						border-left:none;
						font-size:13px;
						color:#1F1F1F;
					}

				}

				.btn-frete{
					color:#FFF;
					width:28px;
					height:28px;
					line-height:28px;
					text-align:center;
					font-size:10px;
					padding:0;
					border-radius:100%;
				}

			}

			.form-control{
				border-color: #FFF;
				font-size:12px;
				color:#8E8E89;

				@include placeholdIt{
					color:#8E8E89;
				}

			}

			.input-group{
				
				.input-group-addon{
					display:flex;

					.btn{
						font-size:13px;
						border-radius: 0;
						@include button-variant(#FFF, #FFF);
						color:#1F1F1F;

						&:hover{
							border-color: #222;
						}
					}

				}

			}

			.btn{
				white-space:normal;
			}

			.card-preco{
				margin-bottom: 15px;
				display: flex;
				flex-wrap: wrap;
				text-align: center;

				.boleto{
					font-size: 18px;
					color:#263351;
				}

				.special{
					color:$theme;
					font-size:13px;
					
					span{
						font-size: 20px;
					}
				}

				.cond{
					color:#263351;
					font-size:12px;
				}

				@include media-breakpoint-up(sm){
					flex-direction: row;
					align-items: center;

					.boleto{
						border-left:2px solid #d1d0d0;
					}
				}

				& > *{
					min-width: 50%;
					padding: 15px;
				}
			}

			.card-body-adicionar{

				.btn{
					padding:6px 12px;
					font-size:18px;

				}

			}

			.compartilhar{

				.h5{
					font-size:12px;
					color:#817F7B;
					font-style:italic;
				}

				a{
					display: inline-block;
					margin-right: 0.5em;
				}
			}
		}

		.avaliar{

			button,
			h2,
			.radio-custom{
			}

			h2 {
				color: #999999;
				font-size: 20px;
				margin-bottom: 45px;
			}

			.subtitle{
				font-size:15px;
				color:#656565;
				margin-bottom:15px;
			}

			.form-group{
				margin-bottom:40px;

				.subtitle{
					margin-bottom:10px;
				}

				.form-control{
					resize:none;
					background-color:#eeeeee;
					@include sombra(#EEE);
					border-radius:6p;
					border:none;
				}

			}

			button{
				min-width:92px;
				padding:13px 20px;
				font-size:13px;
				
			}

			.radio-custom{
				font-size:15px;
				color:#999999;

			}

		}

		.media-legal .badge,
		.depoimento .badge{
			display:inline-block;
		}

	}

	&.internas-meus-pedidos-detalhes{

		.segura-title{

			@include media-breakpoint-up(lg){
				display:flex;
			}

		}

	}

	&.internas-montar-pc{

		.btn-padrao{
			padding:16px 16px;
		}

		.prox-etapa{

			.header-section{

				&.text-green{

					span{
						color:#000;
					}

				}

			}

			.valor-total,
			.parcelado{
				font-family: 'montserratblack';
				line-height:1;
			}

			.valor-total{
				font-size:30px;
			}

			.parcelado{
				font-size:28px;
				color:$theme;

			}

			.fa-angle-double-right{
				font-size:18px;
				vertical-align:sub;
				margin-left:10px;
			}

		}

		.selecionar-qtd{

			.input-group{
				padding-top:20px;
				max-width:150px;
			}

			.btn{
				border-radius:0;
			}

			.form-control{
				font-size:16px;
				height:auto;
				@include place(#282828);
				@include sombra(#CCC);
				text-align:center;
				font-family: 'montserratblack';
			}

		}

		.alerta-fonte{
			background-color:#FFFF00;
			color:#000000;
			font-family: 'montserratextrabold';
			font-size:14px;
			padding:16px 20px 5px 20px;
			margin-bottom:12px;

			.media-incrivel{

				& > *{
					margin-bottom:5px;
				}

			}

		}

	}

	.form-modal{
		padding:20px;
	}

}