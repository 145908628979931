.header-internas{
	font-size:28px;
	text-align:center;
	position:relative;
	z-index:20;

	.title{
		font-size:1em;
		color:$theme;
		margin-bottom:0;
		line-height:1.2;
	}

}