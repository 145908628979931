.radio-custom{

	span{
		position:relative;
		display:inline-block;
		width:1.7em;
		height:1.7em;
		border-radius:100%;
		border:5px solid transparent;
		vertical-align:sub;
		background-color:transparent;
		margin-right:0.6em;
		transition:all 0.6s linear;
		font-size:12px;

		&:before{
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			border-radius:100%;
			transform:scale(1);
			background-color:transparent;
			border:2px solid transparent;
			transition:all 0.6s linear;
		}

	}

	&.radio-custom-grande{
		
		.circle-legal{
			font-size:18px;
			vertical-align:bottom;
		}

	}

	&.radio-custom-medio{
		
		.circle-legal{
			font-size:12px;
			vertical-align:bottom;
		}

	}

	input{
		position:fixed;
		left:-200vw;

		&:checked ~ label > .circle-legal:before{
			transform:scale(1);
		}

	}

	&.radio-bolinha-centro{

		span{
			border:1px solid #95979A;

			&:before{
				border:5px solid transparent;
			}

		}

		input{

			&:checked ~ label > .circle-legal{

				&:before{
					background-color:$theme;
					border-color:#FFF;
				}

			}

		}

	}

	&.radio-custom-padrao{

		.circle-legal{
			background-color:#000000;
		}

		input{

			&:checked ~ label > .circle-legal{
				background-color:#DDDDDD;

				&:before{
					background-color:$theme;
				}

			}

		}

	}

	&.radio-custom-categorias{

		span{
			font-size:9px;
		}

		.circle-legal{
			background-color:#000;
		}

		input{

			&:checked ~ label .circle-legal{
				background-color:$theme;
			}

		}

	}

}