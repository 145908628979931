.rodape{
	text-align:center;
	font-family: 'montserratregular';
	color:#3E3E3E;

	.rodape__btn-whatsapp{
		position:fixed;
		bottom:15px;
		left:15px;
		z-index:400;
		background-color:#4caf50;
		border-radius:100%;
		color:#FFF;
		font-size:40px;
		width:(60 / 40) * 1em;
		height:(60 / 40) * 1em;
		line-height:(60 / 40) * 1em;
		@extend .fab;
		@extend .fa-whatsapp;
		display:inline-flex;
		align-items:center;
		justify-content:center;
		box-shadow:0 0 8px rgba(#000,0.5);
		transition:background-color 0.6s linear;
	
		&:hover,
		&:focus{
			background-color:darken(#4caf50,10%);
			text-decoration:none;
		}
	
		&:after{
			content:'';
			display:block;
			position:absolute;
			top:calc(50% - 7px);
			left:calc(50% - 7px);
			border-radius:100%;
			z-index:-2;
			width:15px;
			height:15px;
			background-color:#4caf50;
			animation:explodeMidiaMenor 4s infinite;
		}
	
	}
	
	@keyframes explodeMidiaMenor{
	
		0%{
			opacity:1;
			transform:scale(1);
		}
	
		100%{
			opacity:0;
			transform:scale(9);
		}
	
	}

	.rodape-vantagens{
		padding-top:15px;
		border-bottom:1px solid #DDDDDD;

		.col-lg-4{

			& + .col-lg-4{

				@include media-breakpoint-down(md){
					border-top:1px solid #DDD;
				}

			}

		}


	}

	.texto{
		font-size:14px;
	}

	.gv8{
		
		img{
			transition:transform 0.6s linear;
		}

		&:hover{

			img{
				transform:translateY(-5px);
			}

		}

	}

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.rodape-principal{
		padding-top:42px;
		position:relative;
		padding-bottom:40px;

		.row-borda{
			border-bottom:1px solid #D3D3D3;
			margin-bottom:48px;
		}

		.title-rodape{
			font-size:15px;
			font-family: 'montserratblack';
			margin-bottom:20px;
		}

		.menu-rodape{
			padding-left:0;
			list-style-type:none;
			margin-bottom:22px;

			li:hover  > a{

				@include media-breakpoint-up(lg){
					color:$theme2;
				}

			}

			li.active > a{
				color:$theme;
			}

			li{
				margin-bottom:15px;
			}

		}

		.media-incrivel{
			margin-bottom:18px;

			.box-text{

				.text-small,
				.link{
					font-family: 'montserratblack';
				}

				.text-small{
					font-size:15px;
					margin-bottom:5px;
				}

				.link{
					font-size:50px;
					line-height:1;

					@include media-breakpoint-down(md){
						font-size:30px;
					}

				}

			}

		}

		.figure-formas{

			.lazyimage{

				@include media-breakpoint-up(lg){
					margin-left:0;
				}

			}

		}

		&.active{

			.img-carro{
				$tranlateY: calc((-2900 / 1920) * 100vw);
				transform: rotate(30deg) translateY($tranlateY);
			}

			&:after{
				opacity:0 !important;
			}

		}

	}

	.rodape-copyright{
		background-color:#333333;
		color:#FFF;
		padding-top:14px;
		padding-bottom:12px;

		&,
		.texto{
			font-size:13px;
		}

		.texto{

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				align-items:center;
			}

		}

	}

	&.rodape-internas{

		.rodape-vantagens{
			background-color:#EEEEEE;
		}

	}

}