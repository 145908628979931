.box-content-index{
	background-color:#FFF;
	padding-left:12px;
	padding-right:12px;
	padding-top:12px;
	padding-bottom:12px;
	text-align:center;

	@include media-breakpoint-up(md){
		position:relative;
		text-align:left;

		@include beforeAfter{
			content:'';
			display:block;
			width:18px;
			height:100%;
			background-color:inherit;
			position:absolute;
			top:0;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

	}

	.box-header{
		padding-top:20px;
		padding-bottom:8px;
		border-bottom:1px solid #DDDDDD;
		margin-bottom:40px;

		@include media-breakpoint-up(lg){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			align-items:center;
		}

		& > *{
			margin-bottom:20px;
			text-align:center;

			@include media-breakpoint-up(lg){
				text-align:left;
			}

		}
		
	}

}