.avaliacoes-area{

	h2{
		color: #333333;
		font-size: 24px;
		margin-bottom: 35px;
	}

	.media{
		font-size: 40px;
		padding-bottom:35px;
		border-bottom:1px solid #e1e1e1;
		margin-bottom:15px;

		.nota{
			color: #666666;
			margin-right:15px;
		}
	}

	.media-legal,
	.depoimento{

		.badge{
			font-size:10px;
			color:#999;
		}

	}

	.rating{


		.fa-star{
			margin-right: 5px;
			color: #CBCBCB;

			&.full{
				color:#F2CA31;
			}

			&.half{
				position: relative;

				&:after{
					content: "\f089";
					color:red;
					position:absolute;
					left:0;
				}
			}
		}
	}

	.depoimento{
		padding:15px 0;
		border-top:1px solid #ddd;

		.rating{
			margin-bottom: 20px;
			font-size:17px;
		}

		.autor{
			color:#656565;
			font-size:15px;
		}

		.depoimento-text{
			margin-bottom: 15px;
			font-size:12px;
			color:#666666;
		}

		& + .depoimento{
			border-top: 1px solid rgba(#000,0.2);
		}
	}

	
	@include media-breakpoint-up(lg){

		.media-legal{
			display:flex;
			flex-wrap:wrap;

			.media-content{
				display:flex;
				flex-wrap:wrap;
				margin-right:10px;

				.rating{
					display:flex;
					align-items:center;
				}

				.nota{
					margin-right:15px;
				}

			}

		}

	}

	@include media-breakpoint-down(lg){

		.media-legal .badge{
			display:block;
		}

	}
}