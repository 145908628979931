.sidebar{
	width: 100%;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;

	.sidebar-header{
		background: $theme;
		color: #FFF;
		display: flex;
		font-size: 12px;
		
		.icon-area{
			align-self: flex-end;
		}

		.icon-area,
		.msg-user,
		.getout a{
			padding: 10px 10px 18px 10px;
		}

		.icon-area{
			padding-right: 0;
			padding-left: 25px;
			padding-bottom: 22px;
		}

		.msg-user,
		.getout{
			
		}

		.msg-user{
			flex-grow: 1;
			font-size:11px;

			.nome{
				color:#FFF;
				font-size:13px;
			}

		}

		.msg-user span{
			display: block;
			
			font-size: 14px;
		}

		.getout{
			display: flex;
			font-size:11px;
		}

		.getout a{
			display:flex;
			align-items: flex-end;
			white-space: nowrap;
			padding-right:20px;

			&:hover{
				color:#FFF;
				text-decoration: none;
				background: rgba(#000,0.1);
			}

			.fa{
				margin-right:1px;
				color:rgba(#FFF,0.8);
			}
		}
	}
	box-shadow:0 0 8px rgba(#ccc,0.8);
	background: #fff;

	.sidebar-body{
		font-size: 14px;
		color: #383838;

		ul{
			list-style: none;
			padding-left:0;
			margin-bottom: 0;
		}

		a{
			display: block;
			padding: 15px 20px;
			font-size:13px;
		}

		li + li{
			border-top: 1px solid #E3E3E3;
		}

		.active a,
		a:hover{
			color: $theme;
		}

	}
}