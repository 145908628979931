.form-alterar-senha{
	max-width: 100%;
	width: 860px;
	margin-left: auto;
	margin-right: auto;

	form{

		label{
			font-size:13px;
			color:#656565;
		}

		.form-group{
			margin-bottom:18px;

			& > .col-md-3,
			& > .col-md-4{
				align-self:center;
				text-align:center;
			}

			.text-gray{
				font-size:12px;
			}

			label,
			.text-gray{
				color:#535353;

			}

			.form-control{
				background-color:#E1E1E1;
				border-radius:0;
				color:#656565;
				border:none;
				@include sombra(#E1E1E1);
				font-size:13px;
				min-height:42px;
				padding-left:20px;

				@include placeholdIt{
					color:#656565;
				}

			}

			.btn{
				
			}

		}

		@include media-breakpoint-up(md){

			label{
				margin-bottom:0;
			}

		}

	}

}