.forma-pagamento{
	color:#333333;
	font-size:13px;
	
	&:first-child{
		padding-top:80px;
	}

	.title-mais-small{
		font-size:13px;
	}

	.title-small{
		font-size:16px;
		margin-bottom:0;
	}

}